import React from "react";
const PTLogo = () => {
  return (
    <svg
      width="110"
      height="23"
      viewBox="0 0 110 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9246 6.02227C36.9246 4.82893 35.9104 3.85813 34.6637 3.85813C34.6637 3.85813 33.2907 3.85813 32.9057 3.85813C32.9057 3.43754 32.9057 0.167969 32.9057 0.167969H30.3936V19.205H32.9057C32.9057 19.205 32.9057 18.0363 32.9057 16.4135C32.9078 16.4215 32.9106 16.4268 32.912 16.4341V6.90265C32.912 6.1122 33.2143 6.05116 33.6868 6.05116C34.1593 6.05116 34.4624 6.1122 34.4624 6.90265V19.205H36.9246V6.02227Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9821 16.2953C52.9821 17.0859 52.6963 17.1469 52.251 17.1469C51.8062 17.1469 51.5212 17.0859 51.5212 16.2953V7.07004C51.5212 6.27959 51.8062 6.2188 52.251 6.2188C52.6963 6.2188 52.9821 6.27959 52.9821 7.07004V16.2953ZM52.244 3.70312C50.443 3.70312 48.9785 5.10519 48.9785 6.82864V16.2365C48.9785 17.9599 50.443 19.3617 52.244 19.3617C54.0442 19.3617 55.5087 17.9599 55.5087 16.2365V6.82864C55.5087 5.10519 54.0442 3.70312 52.244 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.788 3.85938H106.555V16.3616C106.555 16.4991 106.545 16.6126 106.527 16.7096C106.527 16.7537 106.527 16.7856 106.527 16.8019C106.522 16.8019 106.513 16.8019 106.506 16.8019C106.404 17.176 106.142 17.2132 105.779 17.2132C105.417 17.2132 105.155 17.176 105.052 16.8019C105.042 16.8019 105.029 16.8019 105.021 16.8019C105.021 16.7783 105.021 16.7225 105.021 16.6405C105.01 16.5591 105.004 16.4676 105.004 16.3616V3.85938H102.509V17.0425C102.509 18.2355 103.522 19.2063 104.769 19.2063C104.769 19.2063 106.142 19.2063 106.527 19.2063C106.527 19.6259 106.527 22.8448 106.527 22.8448H109.039V3.85938H108.788Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.3305 16.2561C91.3305 16.8229 91.1827 17.0142 90.935 17.0772C90.817 17.136 90.6679 17.1463 90.4931 17.1463C90.0491 17.1463 89.7633 17.0852 89.7633 16.2947V7.06944C89.7633 6.50278 89.9102 6.31176 90.1573 6.24872C90.2753 6.18957 90.4245 6.17964 90.5992 6.17964C91.0446 6.17964 91.3305 6.24043 91.3305 7.03088V16.2561ZM93.4735 0.167969H91.2126C91.2126 0.167969 91.2126 3.43754 91.2126 3.85813C90.8276 3.85813 89.4546 3.85813 89.4546 3.85813C88.2079 3.85813 87.1943 4.82893 87.1943 6.02227V17.0413C87.1943 18.2342 88.2079 19.205 89.4546 19.205H93.7246V0.167969H93.4735Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.7897 16.2953C83.7897 17.0859 83.4866 17.1469 83.0141 17.1469C82.5424 17.1469 82.2392 17.0859 82.2392 16.2953V7.07004C82.2392 6.27959 82.5417 6.2188 83.0141 6.2188C83.4866 6.2188 83.7897 6.27959 83.7897 7.07004V16.2953ZM83.0107 3.70312C81.2104 3.70312 79.7451 5.10519 79.7451 6.82864V16.2365C79.7451 17.9599 81.2104 19.3617 83.0107 19.3617C84.8117 19.3617 86.2762 17.9599 86.2762 16.2365V6.82864C86.2762 5.10519 84.8117 3.70312 83.0107 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3508 0.648413C46.9145 0.260345 46.3713 0.0405651 45.7802 0.0126844L45.5166 0V19.2055H48.0287V2.17607C48.0287 1.58794 47.7817 1.03096 47.3508 0.648413Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4676 0.167969H74.1885V2.57236C74.1885 2.57236 75.8125 2.57236 76.1975 2.57236C76.1975 3.0398 76.1975 19.205 76.1975 19.205H78.7103C78.7103 19.205 78.7103 3.0398 78.7103 2.57236C79.0953 2.57236 80.7194 2.57236 80.7194 2.57236V0.167969H80.4676Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0721 16.2953C42.0721 17.0859 41.7862 17.1469 41.3415 17.1469C40.896 17.1469 40.611 17.0859 40.611 16.2953V7.07004C40.611 6.27959 40.896 6.2188 41.3415 6.2188C41.7862 6.2188 42.0721 6.27959 42.0721 7.07004V16.2953ZM41.3338 3.70312C39.5336 3.70312 38.0684 5.10519 38.0684 6.82864V16.2365C38.0684 17.9599 39.5336 19.3617 41.3338 19.3617C43.1341 19.3617 44.5993 17.9599 44.5993 16.2365V6.82864C44.5993 5.10519 43.1341 3.70312 41.3338 3.70312Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.167969V19.3608H13.9171V0.167969L0 0.167969V0.167969Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.917 8.18663V6.82864C13.917 5.10519 12.4525 3.70312 10.6522 3.70312C8.85117 3.70312 7.38672 5.10519 7.38672 6.82864V10.5711C7.38672 11.764 8.40099 12.7344 9.64763 12.7344C9.64763 12.7344 11.0206 12.7344 11.4056 12.7344C11.4056 12.8869 11.4056 13.4376 11.4056 14.0884C11.4084 14.1402 11.4111 14.1947 11.4111 14.2547V16.5493C11.4111 17.3401 11.1135 17.4012 10.6509 17.4012C10.1881 17.4012 9.89116 17.3401 9.89116 16.5493V14.8786H7.38672V16.2365C7.38672 17.9599 8.85117 19.3617 10.6522 19.3617C12.4525 19.3617 13.917 17.9599 13.917 16.2365V12.4937C13.917 11.3008 12.9034 10.3304 11.6567 10.3304C11.6567 10.3304 10.2839 10.3304 9.89877 10.3304C9.89877 10.0352 9.89877 8.24767 9.89877 7.12981C9.90231 7.12981 9.90704 7.12981 9.90992 7.12981V7.09565C9.90992 6.30544 10.2012 6.24428 10.6551 6.24428C11.1087 6.24428 11.4001 6.30544 11.4001 7.09565V7.12981C11.4014 7.12981 11.4035 7.12981 11.4056 7.12981C11.4056 7.64811 11.4056 8.18663 11.4056 8.18663H13.917Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.01113 7.8344C4.00903 7.8344 4.00627 7.8344 4.0043 7.8344V7.84331C4.0043 8.63377 3.71288 8.69493 3.25915 8.69493C2.80542 8.69493 2.514 8.63377 2.514 7.84331V7.8344C2.51124 7.8344 2.50705 7.8344 2.50495 7.8344C2.50495 6.17336 2.50495 3.73067 2.50495 3.43855C2.50705 3.43855 2.51124 3.43855 2.514 3.43855V3.38279C2.514 2.59221 2.80477 2.53142 3.25915 2.53142C3.71288 2.53142 4.0043 2.59221 4.0043 3.38279V3.43855C4.00627 3.43855 4.00903 3.43855 4.01113 3.43855C4.01113 3.73067 4.01113 6.17336 4.01113 7.8344ZM4.26221 0.167969H-0.0078125V19.205H2.50489C2.50489 19.205 2.50489 11.3423 2.50489 10.8886C2.88921 10.8886 4.26221 10.8886 4.26221 10.8886C5.50886 10.8886 6.52326 9.91803 6.52326 8.72481V2.33161C6.52326 1.13864 5.50886 0.167969 4.26221 0.167969Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6404 15.7185C60.637 15.7185 60.6321 15.7185 60.6293 15.7185V16.2401C60.6293 17.0306 60.3261 17.0914 59.8537 17.0914C59.3813 17.0914 59.0788 17.0306 59.0788 16.2401V7.01445C59.0788 6.224 59.3813 6.16321 59.8537 6.16321C60.3261 6.16321 60.6293 6.224 60.6293 7.01445V7.56302C60.6321 7.56302 60.637 7.56302 60.6404 7.56302C60.6404 9.93753 60.6404 15.3616 60.6404 15.7185ZM59.8869 3.70312C58.0867 3.70312 56.6221 5.10519 56.6221 6.82864V17.0423C56.6221 18.2352 57.6357 19.206 58.8823 19.206C58.8823 19.206 60.2553 19.206 60.6403 19.206C60.6403 19.5177 60.6403 20.2844 60.6403 20.5958C60.2053 20.5958 57.0564 20.5958 57.0564 20.5958V23.0005H60.8922C62.1389 23.0005 63.1524 22.0297 63.1524 20.8365V6.82864C63.1524 5.10519 61.6879 3.70312 59.8869 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9815 17.0556C25.5146 17.0556 25.2148 16.9945 25.2148 16.2039V6.9791C25.2148 6.18852 25.5146 6.12748 25.9815 6.12748C26.449 6.12748 26.7488 6.18852 26.7488 6.9791V8.18663H29.249V6.82864C29.249 5.10519 27.7846 3.70312 25.9836 3.70312C24.1832 3.70312 22.7188 5.10519 22.7188 6.82864V16.2365C22.7188 17.9599 24.1832 19.3617 25.9836 19.3617C27.7846 19.3617 29.249 17.9599 29.249 16.2365V14.8786H26.7488V16.2039C26.7488 16.9945 26.449 17.0556 25.9815 17.0556Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.954 16.2212C98.954 16.4337 98.9304 16.592 98.8874 16.7121C98.8874 16.7504 98.8874 16.7826 98.8874 16.8015C98.8762 16.8015 98.8603 16.8015 98.8479 16.8015C98.7167 17.0448 98.4704 17.0731 98.1479 17.0731C97.8252 17.0731 97.5789 17.0448 97.4485 16.8015C97.4255 16.8015 97.4 16.8015 97.3812 16.8015C97.3812 16.7689 97.3812 16.7038 97.3812 16.6165C97.3563 16.5107 97.343 16.3814 97.343 16.2212V10.6782C97.343 10.5178 97.3563 10.3887 97.3812 10.2829C97.3812 10.2417 97.3812 10.2074 97.3812 10.1878C97.3889 10.1878 97.4 10.1878 97.4082 10.1878C97.5241 9.8605 97.7885 9.8266 98.1479 9.8266C98.6383 9.8266 98.954 9.88738 98.954 10.6782V16.2212ZM99.1393 3.85938H95.3034V6.26389C95.3034 6.26389 98.4524 6.26389 98.8873 6.26389C98.8873 6.58527 98.8873 7.46201 98.8873 7.78376C98.5024 7.78376 97.1294 7.78376 97.1294 7.78376C95.8827 7.78376 94.8691 8.75456 94.8691 9.94753V17.0425C94.8691 18.2355 95.8827 19.2063 97.1294 19.2063H101.399V6.02352C101.399 4.83017 100.385 3.85938 99.1393 3.85938Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3231 3.85938H19.0899V16.3616C19.0899 16.4991 19.0801 16.6126 19.0628 16.7096C19.0628 16.7537 19.0628 16.7856 19.0628 16.8019C19.0565 16.8019 19.0483 16.8019 19.042 16.8019C18.9394 17.176 18.6771 17.2132 18.3143 17.2132C17.9521 17.2132 17.6898 17.176 17.5879 16.8019C17.5782 16.8019 17.5649 16.8019 17.556 16.8019C17.556 16.7783 17.556 16.7225 17.556 16.6405C17.5456 16.5591 17.5393 16.4676 17.5393 16.3616V3.85938H15.0439V17.0425C15.0439 18.2355 16.0581 19.2063 17.3049 19.2063C17.3049 19.2063 18.6777 19.2063 19.0628 19.2063C19.0628 19.6259 19.0628 22.8448 19.0628 22.8448H21.5749V3.85938H21.3231Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.3512 3.85938H68.118V16.3616C68.118 16.4991 68.1075 16.6126 68.0902 16.7096C68.0902 16.7537 68.0902 16.7856 68.0902 16.8019C68.084 16.8019 68.0756 16.8019 68.0694 16.8019C67.9667 17.176 67.7045 17.2132 67.3417 17.2132C66.9802 17.2132 66.718 17.176 66.6153 16.8019C66.6056 16.8019 66.5924 16.8019 66.5833 16.8019C66.5833 16.7783 66.5833 16.7225 66.5833 16.6405C66.573 16.5591 66.5675 16.4676 66.5675 16.3616V3.85938H64.0713V17.0425C64.0713 18.2355 65.0856 19.2063 66.3322 19.2063C66.3322 19.2063 67.7052 19.2063 68.0902 19.2063C68.0902 19.6259 68.0902 22.8448 68.0902 22.8448H70.6022V3.85938H70.3512Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9246 6.02227C36.9246 4.82893 35.9104 3.85813 34.6637 3.85813C34.6637 3.85813 33.2907 3.85813 32.9057 3.85813C32.9057 3.43754 32.9057 0.167969 32.9057 0.167969H30.3936V19.205H32.9057C32.9057 19.205 32.9057 18.0363 32.9057 16.4135C32.9078 16.4215 32.9106 16.4268 32.912 16.4341V6.90265C32.912 6.1122 33.2143 6.05116 33.6868 6.05116C34.1593 6.05116 34.4624 6.1122 34.4624 6.90265V19.205H36.9246V6.02227Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9821 16.2953C52.9821 17.0859 52.6963 17.1469 52.251 17.1469C51.8062 17.1469 51.5212 17.0859 51.5212 16.2953V7.07004C51.5212 6.27959 51.8062 6.2188 52.251 6.2188C52.6963 6.2188 52.9821 6.27959 52.9821 7.07004V16.2953ZM52.244 3.70312C50.443 3.70312 48.9785 5.10519 48.9785 6.82864V16.2365C48.9785 17.9599 50.443 19.3617 52.244 19.3617C54.0442 19.3617 55.5087 17.9599 55.5087 16.2365V6.82864C55.5087 5.10519 54.0442 3.70312 52.244 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.788 3.85938H106.555V16.3616C106.555 16.4991 106.545 16.6126 106.527 16.7096C106.527 16.7537 106.527 16.7856 106.527 16.8019C106.522 16.8019 106.513 16.8019 106.506 16.8019C106.404 17.176 106.142 17.2132 105.779 17.2132C105.417 17.2132 105.155 17.176 105.052 16.8019C105.042 16.8019 105.029 16.8019 105.021 16.8019C105.021 16.7783 105.021 16.7225 105.021 16.6405C105.01 16.5591 105.004 16.4676 105.004 16.3616V3.85938H102.509V17.0425C102.509 18.2355 103.522 19.2063 104.769 19.2063C104.769 19.2063 106.142 19.2063 106.527 19.2063C106.527 19.6259 106.527 22.8448 106.527 22.8448H109.039V3.85938H108.788Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.3305 16.2561C91.3305 16.8229 91.1827 17.0142 90.935 17.0772C90.817 17.136 90.6679 17.1463 90.4931 17.1463C90.0491 17.1463 89.7633 17.0852 89.7633 16.2947V7.06944C89.7633 6.50278 89.9102 6.31176 90.1573 6.24872C90.2753 6.18957 90.4245 6.17964 90.5992 6.17964C91.0446 6.17964 91.3305 6.24043 91.3305 7.03088V16.2561ZM93.4735 0.167969H91.2126C91.2126 0.167969 91.2126 3.43754 91.2126 3.85813C90.8276 3.85813 89.4546 3.85813 89.4546 3.85813C88.2079 3.85813 87.1943 4.82893 87.1943 6.02227V17.0413C87.1943 18.2342 88.2079 19.205 89.4546 19.205H93.7246V0.167969H93.4735Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.7897 16.2953C83.7897 17.0859 83.4866 17.1469 83.0141 17.1469C82.5424 17.1469 82.2392 17.0859 82.2392 16.2953V7.07004C82.2392 6.27959 82.5417 6.2188 83.0141 6.2188C83.4866 6.2188 83.7897 6.27959 83.7897 7.07004V16.2953ZM83.0107 3.70312C81.2104 3.70312 79.7451 5.10519 79.7451 6.82864V16.2365C79.7451 17.9599 81.2104 19.3617 83.0107 19.3617C84.8117 19.3617 86.2762 17.9599 86.2762 16.2365V6.82864C86.2762 5.10519 84.8117 3.70312 83.0107 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3508 0.648413C46.9145 0.260345 46.3713 0.0405651 45.7802 0.0126844L45.5166 0V19.2055H48.0287V2.17607C48.0287 1.58794 47.7817 1.03096 47.3508 0.648413Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4676 0.167969H74.1885V2.57236C74.1885 2.57236 75.8125 2.57236 76.1975 2.57236C76.1975 3.0398 76.1975 19.205 76.1975 19.205H78.7103C78.7103 19.205 78.7103 3.0398 78.7103 2.57236C79.0953 2.57236 80.7194 2.57236 80.7194 2.57236V0.167969H80.4676Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0721 16.2953C42.0721 17.0859 41.7862 17.1469 41.3415 17.1469C40.896 17.1469 40.611 17.0859 40.611 16.2953V7.07004C40.611 6.27959 40.896 6.2188 41.3415 6.2188C41.7862 6.2188 42.0721 6.27959 42.0721 7.07004V16.2953ZM41.3338 3.70312C39.5336 3.70312 38.0684 5.10519 38.0684 6.82864V16.2365C38.0684 17.9599 39.5336 19.3617 41.3338 19.3617C43.1341 19.3617 44.5993 17.9599 44.5993 16.2365V6.82864C44.5993 5.10519 43.1341 3.70312 41.3338 3.70312Z"
        fill="white"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.167969V19.3608H13.9171V0.167969L0 0.167969V0.167969Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.917 8.18663V6.82864C13.917 5.10519 12.4525 3.70312 10.6522 3.70312C8.85117 3.70312 7.38672 5.10519 7.38672 6.82864V10.5711C7.38672 11.764 8.40099 12.7344 9.64763 12.7344C9.64763 12.7344 11.0206 12.7344 11.4056 12.7344C11.4056 12.8869 11.4056 13.4376 11.4056 14.0884C11.4084 14.1402 11.4111 14.1947 11.4111 14.2547V16.5493C11.4111 17.3401 11.1135 17.4012 10.6509 17.4012C10.1881 17.4012 9.89116 17.3401 9.89116 16.5493V14.8786H7.38672V16.2365C7.38672 17.9599 8.85117 19.3617 10.6522 19.3617C12.4525 19.3617 13.917 17.9599 13.917 16.2365V12.4937C13.917 11.3008 12.9034 10.3304 11.6567 10.3304C11.6567 10.3304 10.2839 10.3304 9.89877 10.3304C9.89877 10.0352 9.89877 8.24767 9.89877 7.12981C9.90231 7.12981 9.90704 7.12981 9.90992 7.12981V7.09565C9.90992 6.30544 10.2012 6.24428 10.6551 6.24428C11.1087 6.24428 11.4001 6.30544 11.4001 7.09565V7.12981C11.4014 7.12981 11.4035 7.12981 11.4056 7.12981C11.4056 7.64811 11.4056 8.18663 11.4056 8.18663H13.917Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.01113 7.8344C4.00903 7.8344 4.00627 7.8344 4.0043 7.8344V7.84331C4.0043 8.63377 3.71288 8.69493 3.25915 8.69493C2.80542 8.69493 2.514 8.63377 2.514 7.84331V7.8344C2.51124 7.8344 2.50705 7.8344 2.50495 7.8344C2.50495 6.17336 2.50495 3.73067 2.50495 3.43855C2.50705 3.43855 2.51124 3.43855 2.514 3.43855V3.38279C2.514 2.59221 2.80477 2.53142 3.25915 2.53142C3.71288 2.53142 4.0043 2.59221 4.0043 3.38279V3.43855C4.00627 3.43855 4.00903 3.43855 4.01113 3.43855C4.01113 3.73067 4.01113 6.17336 4.01113 7.8344ZM4.26221 0.167969H-0.0078125V19.205H2.50489C2.50489 19.205 2.50489 11.3423 2.50489 10.8886C2.88921 10.8886 4.26221 10.8886 4.26221 10.8886C5.50886 10.8886 6.52326 9.91803 6.52326 8.72481V2.33161C6.52326 1.13864 5.50886 0.167969 4.26221 0.167969Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6404 15.7185C60.637 15.7185 60.6321 15.7185 60.6293 15.7185V16.2401C60.6293 17.0306 60.3261 17.0914 59.8537 17.0914C59.3813 17.0914 59.0788 17.0306 59.0788 16.2401V7.01445C59.0788 6.224 59.3813 6.16321 59.8537 6.16321C60.3261 6.16321 60.6293 6.224 60.6293 7.01445V7.56302C60.6321 7.56302 60.637 7.56302 60.6404 7.56302C60.6404 9.93753 60.6404 15.3616 60.6404 15.7185ZM59.8869 3.70312C58.0867 3.70312 56.6221 5.10519 56.6221 6.82864V17.0423C56.6221 18.2352 57.6357 19.206 58.8823 19.206C58.8823 19.206 60.2553 19.206 60.6403 19.206C60.6403 19.5177 60.6403 20.2844 60.6403 20.5958C60.2053 20.5958 57.0564 20.5958 57.0564 20.5958V23.0005H60.8922C62.1389 23.0005 63.1524 22.0297 63.1524 20.8365V6.82864C63.1524 5.10519 61.6879 3.70312 59.8869 3.70312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9815 17.0556C25.5146 17.0556 25.2148 16.9945 25.2148 16.2039V6.9791C25.2148 6.18852 25.5146 6.12748 25.9815 6.12748C26.449 6.12748 26.7488 6.18852 26.7488 6.9791V8.18663H29.249V6.82864C29.249 5.10519 27.7846 3.70312 25.9836 3.70312C24.1832 3.70312 22.7188 5.10519 22.7188 6.82864V16.2365C22.7188 17.9599 24.1832 19.3617 25.9836 19.3617C27.7846 19.3617 29.249 17.9599 29.249 16.2365V14.8786H26.7488V16.2039C26.7488 16.9945 26.449 17.0556 25.9815 17.0556Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.954 16.2212C98.954 16.4337 98.9304 16.592 98.8874 16.7121C98.8874 16.7504 98.8874 16.7826 98.8874 16.8015C98.8762 16.8015 98.8603 16.8015 98.8479 16.8015C98.7167 17.0448 98.4704 17.0731 98.1479 17.0731C97.8252 17.0731 97.5789 17.0448 97.4485 16.8015C97.4255 16.8015 97.4 16.8015 97.3812 16.8015C97.3812 16.7689 97.3812 16.7038 97.3812 16.6165C97.3563 16.5107 97.343 16.3814 97.343 16.2212V10.6782C97.343 10.5178 97.3563 10.3887 97.3812 10.2829C97.3812 10.2417 97.3812 10.2074 97.3812 10.1878C97.3889 10.1878 97.4 10.1878 97.4082 10.1878C97.5241 9.8605 97.7885 9.8266 98.1479 9.8266C98.6383 9.8266 98.954 9.88738 98.954 10.6782V16.2212ZM99.1393 3.85938H95.3034V6.26389C95.3034 6.26389 98.4524 6.26389 98.8873 6.26389C98.8873 6.58527 98.8873 7.46201 98.8873 7.78376C98.5024 7.78376 97.1294 7.78376 97.1294 7.78376C95.8827 7.78376 94.8691 8.75456 94.8691 9.94753V17.0425C94.8691 18.2355 95.8827 19.2063 97.1294 19.2063H101.399V6.02352C101.399 4.83017 100.385 3.85938 99.1393 3.85938Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3231 3.85938H19.0899V16.3616C19.0899 16.4991 19.0801 16.6126 19.0628 16.7096C19.0628 16.7537 19.0628 16.7856 19.0628 16.8019C19.0565 16.8019 19.0483 16.8019 19.042 16.8019C18.9394 17.176 18.6771 17.2132 18.3143 17.2132C17.9521 17.2132 17.6898 17.176 17.5879 16.8019C17.5782 16.8019 17.5649 16.8019 17.556 16.8019C17.556 16.7783 17.556 16.7225 17.556 16.6405C17.5456 16.5591 17.5393 16.4676 17.5393 16.3616V3.85938H15.0439V17.0425C15.0439 18.2355 16.0581 19.2063 17.3049 19.2063C17.3049 19.2063 18.6777 19.2063 19.0628 19.2063C19.0628 19.6259 19.0628 22.8448 19.0628 22.8448H21.5749V3.85938H21.3231Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.3512 3.85938H68.118V16.3616C68.118 16.4991 68.1075 16.6126 68.0902 16.7096C68.0902 16.7537 68.0902 16.7856 68.0902 16.8019C68.084 16.8019 68.0756 16.8019 68.0694 16.8019C67.9667 17.176 67.7045 17.2132 67.3417 17.2132C66.9802 17.2132 66.718 17.176 66.6153 16.8019C66.6056 16.8019 66.5924 16.8019 66.5833 16.8019C66.5833 16.7783 66.5833 16.7225 66.5833 16.6405C66.573 16.5591 66.5675 16.4676 66.5675 16.3616V3.85938H64.0713V17.0425C64.0713 18.2355 65.0856 19.2063 66.3322 19.2063C66.3322 19.2063 67.7052 19.2063 68.0902 19.2063C68.0902 19.6259 68.0902 22.8448 68.0902 22.8448H70.6022V3.85938H70.3512Z"
        fill="white"
      />
    </svg>
  );
};

export default PTLogo;
